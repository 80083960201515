<template>
  <!-- 讲师详情 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <!-- <template slot="extra">
        <a-button> 离编/在编 </a-button>
      </template> -->
    </a-page-header>
    <div class="main-content">
      <div class="body" v-if="info">
        <div class="body-left">
          <div class="user">
            <div class="avatar">
              <img class="img" :src="info.avatarUrl" />
            </div>
            <div class="name">{{ info.realName }}</div>
            <a-tag color="green" v-if="info.status === 1">
              渠道经理状态：{{ statuText[info.status] }}
            </a-tag>
            <a-tag v-else>门店状态：{{ statuText[info.status] }}</a-tag>
          </div>
        </div>

        <div class="body-right">
          <div class="text-content" style="position: relative">
            <div class="content-item">
              <div class="content-item-header">
                <div class="title">渠道经理信息</div>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">渠道经理名称：</span>
                  <span>{{ info.storename }}</span>
                </div>
                <div class="li">
                  <span class="label">使用者姓名：</span>
                  <span>{{ info.username }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">系统账号：</span>
                  <span>{{ info.systemNum }}</span>
                </div>
                <div class="li">
                  <span class="label">创建时间：</span>
                  <span>{{ info.createAt }}</span>
                </div>
              </div>
               <div class="ul" v-if="info.isFowller == 1">
                <div class="li">
                  <span class="label">监工名称：</span>
                    <span>{{ info.fowller.realName }}</span>
                </div>
                <div class="li">
                  <span class="label">监工比例：</span>
                  <span>{{ info.fowllerRate }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">详细地址：</span>
                  <span>{{ info.address }}</span>
                </div>
                <div class="li">
                  <span class="label">绑定的地区：</span>
                  <span>{{ info.district }}</span>
                </div>
              </div>
            </div>
            <div class="content-sub-title" style="margin-top: 30px">
              旗下业务员
            </div>

            <a-table
              :columns="columns"
              :data-source="infoCode"
              :pagination="false"
              :row-key="(record) => record.id"
              :scroll="{ x: 1050 }"
            >
              <template slot="avatar" slot-scope="record">
                <div class="avatarUrl">
                  <img class="img" :src="record.avatar" alt="avatar" />
                </div>
              </template>
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="onChange(record)">
                  编辑
                </a-button>
              </template>
            </a-table>

            <div class="content-sub-title" style="margin-top: 30px">
              旗下师傅
            </div>

            <a-table
              :columns="columnsMaster"
              :data-source="infList"
              :pagination="false"
              :row-key="(record) => record.id"
              :scroll="{ x: 1050 }"
            >
              <template slot="avatar" slot-scope="record">
                <div class="avatarUrl">
                  <img class="img" :src="record.avatar" alt="avatar" />
                </div>
              </template>
            </a-table>
            <div class="dialog-posit">
              <a-button @click="overseerClick()" style="margin-right: 10px"
                >{{info.isFowller == 1 ? '已绑定监工管理' :'绑定监工管理'}}</a-button
              >
              <a-button @click="modalVisibleClick()">成为合作伙伴</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分享二维码弹窗  :before-close="handleClose"-->
    <a-modal v-model="modalVisible" title="分享微信" :footer="false">
      <div class="codeWx">
        <iframe
          id="iframe"
          :src="wechatLink"
          width="300px"
          height="400px"
          frameborder="0"
          name="iframea"
          sandbox="allow-scripts allow-top-navigation allow-same-origin"
        ></iframe>
      </div>
    </a-modal>
    <!-- 绑定监工 -->
    <a-modal
      title="绑定监工管理"
      :visible="overseer"
      @cancel="handleModalCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="formTion"
        :rules="rulesTion"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item ref="name" label="监工管理" prop="overlineValue">
          <a-select v-model="formTion.overlineValue">
            <a-select-option
              v-for="item in dataSource"
              :value="item.id"
              :key="item.realName"
              >{{ item.realName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="name" label="监工比例" prop="proportion">
          <a-input
            placeholder="请输入监工1到29的比例"
            v-model="formTion.proportion"
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleModalCancel"> 取消 </a-button>
        <a-button type="primary" @click="sumbitFowll"> 确定 </a-button>
      </template>
    </a-modal>
    <!-- 订单列表 -->
    <a-modal
      title="订单列表"
      :visible="isModalExport"
      :footer="false"
      :width="700"
      @cancel="onModalExportCancel"
    >
      <div class="find-order">
        <div class="find">
          <div class="timer-picker">
            <span class="ribbon-label">按完成时间段：</span>
            <a-date-picker v-model="startAt2" placeholder="开始日期" />
            <span class="ribbon-center-text">至</span>
            <a-date-picker v-model="endAt2" placeholder="结束日期" />
          </div>
          <div class="btn-box">
            <a-button
              class="search-btn"
              type="primary"
              :disabled="!startAt2 || !endAt2"
              :loading="findBtnLoading"
              @click="onFindOrder"
            >
              查 询
            </a-button>
            <a-button
              class="reset-btn"
              :disabled="!startAt2 && !endAt2"
              @click="onReset2"
            >
              重 置
            </a-button>
          </div>
        </div>
        <!-- <a-alert
          :message="`（${findTime}）时间段内查询的订单，共${findNum}条`"
          type="success"
          show-icon
          v-if="isFindFinish"
        />
        <div class="footer-btn" v-if="isFindFinish">
          <a-button
            class="export-btn"
            type="primary"
            :loading="exportBtnLoading"
            :disabled="!findNum"
            @click="onExport"
          >
            导出
          </a-button>
        </div> -->
        <!-- <a-table
        class="device-tabel"
        :columns="columns2"
        :data-source="devices"
        :pagination="false"
        :row-key="(record) => record.id"
        :loading="deviceLoading"
      >
        <template slot="price" slot-scope="records">
          <a-statistic prefix="￥" :value="records.price" />
        </template>
      </a-table> -->
      </div>
    </a-modal>
    <!--  -->
  </div>
</template>
<script>
let lectorId = ''
let lectorCommunityId = ''
import moment from 'moment'
export default {
  data() {
    return {
      title: '',
      info: null,
      statuText: {
        1: '启用',
        0: '禁用'
      },
      //同意比例
      formTion: {
        proportion: '',
        overlineValue: ''
      },
      rulesTion: {
        proportion: [
          { required: true, message: '请输入监工比例', trigger: 'blur' }
        ],
        overlineValue: [
          { required: true, message: '请选择绑定监工', trigger: '' }
        ]
      },
      modalVisible: false,
      wechatLink: '',
      columns: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '昵称',
          width: 100,
          dataIndex: 'nickname'
        },
        {
          title: '微信头像',
          width: 100,
          scopedSlots: {
            customRender: 'avatar'
          }
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
          width: 200
        },
        {
          title: '操作',
          width: 300,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columnsMaster: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '真实姓名',
          width: 100,
          dataIndex: 'realName'
        },
        {
          title: '微信头像',
          width: 100,
          scopedSlots: {
            customRender: 'avatar'
          }
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
          width: 200
        }
      ],
      infoCode: [],
      infList: [],
      isModalExport: false,
      startAt2: '',
      endAt2: '',
      findBtnLoading: false,
      orderIdArr: '',
      overseer: false,
      dataSource: []
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    lectorId = query.id
    lectorCommunityId = query.communityId
    this.getCommunityInfo()
    this.getCommunitySpread()
    this.getCommunityServicer()
    this.getChannelBusiness()
    if (query.code) {
      // 绑定微信
      // this.stepsCurrent = 1
      const params = {
        code: query.code,
        userType: query.userType,
        id: query.id
      }
      this.channelBusinessBindWX(params)
    }
  },
  methods: {
    // 获取监工管理列表数据
    getChannelBusiness() {
      // 获取监工管理列表
      this.$axios.getFowller().then((res) => {
        const l = res.data.data
        this.dataSource = l
        console.log('dataSource', l)
      })
    },
    getCommunityInfo() {
      // 获取渠道经理详情
      const data = {
        id: lectorId
      }

      this.$axios.getCommunityInfo(data).then((res) => {
        this.info = res.data.data
      })
    },
    // 绑定监工管理
    overseerClick() {
      this.overseer = true
    },
    getCommunitySpread() {
      const data = {
        id: lectorCommunityId
      }
      this.$axios.getCommunitySpread(data).then((res) => {
        this.infoCode = res.data.data.list
      })
    },
    getCommunityServicer() {
      const data = {
        id: lectorCommunityId
      }
      this.$axios.getCommunityServicer(data).then((res) => {
        this.infList = res.data.data.list
        console.log('getCommunityServicer', this.infoCode)
      })
    },
    // 点击获取二维码
    modalVisibleClick() {
      const data = {
        id: lectorId
      }
      this.$axios.getComnunityNeed(data).then((res) => {
        this.modalVisible = true
        this.info = res.data.data
        this.wechatLink = res.data.data.url
        console.log('data', this.info)
      })
    },
    channelBusinessBindWX(data) {
      // 绑定微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.getBecomeCommunitySpread(data).then(() => {
        hide()
        this.$message.success('操作成功')
        this.modalVisible = false
      })
    },
    // 查看订单
    onChange(res) {
      this.isModalExport = true
      this.orderIdArr = res
    },
    // 时间选择
    onModalExportCancel() {
      // 取消-批量导出
      this.isModalExport = false
      this.exportBtnLoading = false
      this.isFindFinish = false
    },
    onFindOrder() {
      // 查询订单导出数量
      this.findBtnLoading = true
      const startAt = moment(this.startAt2).format('YYYY-MM-DD') + ' 00:00:00'
      const endAt = moment(this.endAt2).format('YYYY-MM-DD') + ' 23:59:59'
      const t = `${startAt.substr(0, 10)} 至 ${endAt.substr(5, 5)}`
      this.findTime = t
      const data = {
        startAt: startAt,
        endAt: endAt,
        spreadId: this.orderIdArr.id
      }
      this.$axios.getSpreadOrderList(data).then((res) => {
        // this.findNum = Number(res.data.data)
        console.log('resddddd', res.data.data)
        this.findBtnLoading = false
        this.isFindFinish = true
      })
    },
    onReset2() {
      // 重置时间
      this.startAt2 = ''
      this.endAt2 = ''
      this.isFindFinish = false
    },
    handleModalCancel() {
      this.overseer = false
      this.$refs.ruleForm.resetFields()
      // this.formTion.proportion = ''
    },
    sumbitFowll() {
       console.log("formTion",this.formTion,lectorId)
        const data = {
        communityId : lectorId,
        fowllerId: this.formTion.overlineValue
      }
      // console.log("formTion",this.formTion,lectorId)
      if (!/(^[1-9]\d*$)/.test(this.formTion.proportion)) {
        this.$message.error('请输入正整数0也不能输入')
        return false
      }
      if (this.formTion.proportion <30) {
        data.fowllerRate = this.formTion.proportion / 100
      } else {
        this.$message.error('比例不能超出30')
        return
      }
      this.$axios.getBindFowller(data).then(() => {
        this.$message.success('操作成功')
        this.overseer = false
        // this.getCommunityServicer()
      })
    }
    // getTrainingInfo() {
    //   // 获取培训实操讲师详情
    //   const data = {
    //     id: lectorId
    //   }
    // this.$axios.getTrainingInfo(data).then((res) => {
    //   this.info = res.data.data
    // })
    // }
  }
}
</script>
<style scoped>
.body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.body-left,
.text-content,
.id-card-content {
  background-color: #fff;
  border-radius: 2px;
}

.body-left {
  width: 238px;
  height: 100%;
  padding: 32px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  margin: 8px 0;
}

.body-right {
  flex: 1;
  margin-left: 24px;
}

.text-content,
.id-card-content {
  padding: 20px 32px 24px 32px;
}

.id-card-content {
  margin-top: 16px;
}

.content-item-flex {
  display: flex;
  align-items: center;
}

.content-item-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.ul-1 {
  margin-top: 16px;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
  display: inline-block;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul .li:not(:last-child) {
  margin-right: 46px;
}

.ul .label {
  font-weight: bold;
}

.ul .address {
  width: auto;
}

.area {
  margin-left: 56px;
}

.id-card {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
}

.id-card-img {
  display: flex;
}

.id-card-img .item {
  width: 160px;
  height: 90px;
}

.id-card-img .item-2 {
  margin-left: 24px;
}

.id-card-img .text {
  text-align: center;
  line-height: 22px;
  margin-top: 8px;
}

.divider-vertical {
  height: 100px;
  margin: 10px 32px 10px 32px;
}

.id-card-text .text-1 {
  margin: 30px 0 16px 0;
}
.dialog-posit {
  position: absolute;
  right: 5%;
  top: 5%;
}
.avatarUrl {
  width: 50px;
  height: 50px;
}
.codeWx {
  width: 300px;
  height: 400px;
  margin: 0 auto;
}
.codeToken {
  text-align: center;
  margin: 30px 0 10px 0;
  font-size: 18px;
}
.find {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-btn {
  margin-right: 10px;
}
</style>
